import { HttpService } from '@geovelo-frontends/commons';
import { navigate } from 'gatsby';
import React, { ReactElement, useContext, useEffect, useState } from 'react';

import { AppContext } from '../context';
import useQueryParams from '../hooks/query-params';
import { TPageProps } from '../page-props';
import LoadingPage from '../templates/loading';

function GuestPage({
  location: { search, state },
  pageContext,
  element,
}: TPageProps & { element: ReactElement }) {
  const {
    user: { current: currentUser },
  } = useContext(AppContext);
  const { get: getQueryParams } = useQueryParams(search);
  const [redirectUrl] = useState(getQueryParams()['redirect-url']);
  const [redirectParams] = useState(getQueryParams()['redirect-params']);

  useEffect(() => {
    if (currentUser && !pageContext.allowSignInUser) {
      if (redirectUrl) {
        const queryParams = redirectParams
          ?.split(',')
          .reduce<{ [key: string]: string | number }>((res, param) => {
            if (param === 'userId') res.userId = currentUser.id;
            else if (param === 'authorizationToken' && HttpService.authorizationToken)
              res.authorizationToken = HttpService.authorizationToken;
            return res;
          }, {});

        window.open(
          `${redirectUrl}${
            queryParams
              ? `?${Object.keys(queryParams)
                  .map((key) => `${key}=${queryParams[key]}`)
                  .join('&')}`
              : ''
          }`,
          '_self',
        );
      } else navigate(`${state?.prevPath || '/'}${state?.search || ''}`);
    }
  }, [currentUser]);

  if (currentUser && !pageContext.allowSignInUser) return <></>;

  if (currentUser === undefined && typeof window !== 'undefined')
    return <LoadingPage loadingTextKey="commons.loading" />;

  return <>{element}</>;
}

export default GuestPage;
