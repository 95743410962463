import {
  CompanyIcon,
  HttpService,
  PeopleIcon,
  PersonIcon,
  useTracker,
} from '@geovelo-frontends/commons';
import {
  Box,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Drawer as MuiDrawer,
  Toolbar,
} from '@mui/material';
import { GatsbyLinkProps, Link } from 'gatsby';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { HeaderDataFragment } from '../../../graphql-types';
import Button from '../../components/button';
import { SignOutIcon } from '../../components/icons';
import { AppContext } from '../../context';
import environment from '../../environment';
import { TPageProps } from '../../page-props';

function Drawer({
  open,
  headerData,
  toggle,
}: {
  headerData?: HeaderDataFragment;
  open: boolean;
  toggle: (open: boolean) => void;
} & Omit<TPageProps, 'children'>): JSX.Element {
  const { trackEvent } = useTracker();
  const {
    user: { current: currentUser, employee },
    actions: { signOut },
  } = useContext(AppContext);
  const {
    t,
    i18n: { language: currentLanguage },
  } = useTranslation();

  return (
    <>
      <MuiDrawer
        anchor="top"
        onClose={() => toggle(false)}
        open={open}
        sx={{
          width: '100%',
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { minHeight: '100%', width: '100%', boxSizing: 'border-box' },
        }}
      >
        <Toolbar sx={{ height: 72 }} />
        <Box display="flex" flexDirection="column" flexGrow={1}>
          <List component="nav">
            {headerData?.body[0].items
              .filter(
                ({ item_parent_key, item_link, item_title }) =>
                  (item_link?.url && item_title?.text) || item_parent_key?.text,
              )
              .map(({ item_link, item_title }, index) => {
                return (
                  <StyledListItemButton
                    activeClassName="active"
                    component={Link}
                    key={index}
                    onClick={() => toggle(false)}
                    to={item_link?.url || ''}
                  >
                    <ListItemText primary={item_title?.text} />
                  </StyledListItemButton>
                );
              })}
          </List>
          {currentUser && (
            <>
              <Divider />
              <List component="nav">
                <StyledListItemButton
                  activeClassName="active"
                  component={Link}
                  onClick={() => toggle(false)}
                  to={`/${currentLanguage}/user`}
                >
                  <ListItemIcon>
                    <PersonIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      headerData?.profile_button_label?.text || t('geovelo.navigation.my_profile')
                    }
                  />
                </StyledListItemButton>
                <StyledListItemButton
                  activeClassName="active"
                  component={Link}
                  onClick={() => toggle(false)}
                  to={`/${currentLanguage}/communities`}
                >
                  <ListItemIcon>
                    <PeopleIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={t('commons.communities.joined_communities.title')} />
                </StyledListItemButton>
                {employee && (
                  <ListItemButton
                    component="a"
                    href={`${environment.enterpriseUrl}?auth-token=${
                      HttpService.authorizationToken?.substring(
                        HttpService.authorizationToken.indexOf(' ') + 1,
                      ) || ''
                    }&user-id=${currentUser.id}`}
                  >
                    <ListItemIcon>
                      <CompanyIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText primary="Geovelo Entreprise" />
                  </ListItemButton>
                )}
              </List>
            </>
          )}
        </Box>
        <Box alignItems="center" display="flex" flexDirection="column" flexShrink={0} padding={2}>
          {currentUser ? (
            <Button
              color="inherit"
              onClick={() => {
                toggle(false);
                signOut();
              }}
              size="small"
              startIcon={<SignOutIcon />}
              sx={{ maxWidth: '100%', width: 300 }}
              variant="outlined"
            >
              {t('commons.actions.sign_out')}
            </Button>
          ) : (
            currentUser === null && (
              <Button
                color="primary"
                component={Link}
                onClick={() => {
                  trackEvent('Sign in page redirection', 'Clicked', 'CTA sign in header');
                  toggle(false);
                }}
                size="medium"
                sx={{ maxWidth: '100%', width: 300 }}
                to={`/${currentLanguage}/sign-in`}
                variant="outlined"
              >
                {headerData?.signin_button_label?.text || t('commons.actions.sign_in')}
              </Button>
            )
          )}
        </Box>
      </MuiDrawer>
    </>
  );
}

const StyledListItemButton = styled(ListItemButton)<
  { component: typeof Link } & GatsbyLinkProps<Record<string, never>>
>`
  &.active {
    color: ${({ theme }) => theme.palette.secondary.main};
  }
`;

export default Drawer;
