import { MatomoProvider } from '@jonkoops/matomo-tracker-react';
import { browserTracingIntegration } from '@sentry/browser';
import * as Sentry from '@sentry/gatsby';
import { RouteUpdateArgs, WrapPageElementBrowserArgs, WrapRootElementBrowserArgs } from 'gatsby';
import React, { ReactElement } from 'react';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';

import PageElementWrapper from './src/page-element-wrapper';
import { TPageContext, TPageData, TPageState } from './src/page-props';
import RootElementWrapper from './src/root-element-wrapper';
import { matomoInstance } from './src/tracking';

if (process.env.GATSBY_APP_ENV === 'production') {
  Sentry.init({
    dsn: 'https://4856d5efd7004b8789bddd0ec8cbfeb1@o310561.ingest.sentry.io/4504798235066368',
    // debug: process.env.GATSBY_APP_ENV === 'development',
    environment: process.env.GATSBY_APP_ENV,
    integrations: [browserTracingIntegration()],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.001,
    beforeSend(event, hint) {
      const error = hint.originalException as Error;
      if (event.tags?.['browser.name'] === 'FacebookBot') return null;
      if (error && error.message && error.message.match(/Failed to initialize WebGL./i))
        return null;
      return event;
    },
  });
}

export const wrapRootElement = ({ ...props }: WrapRootElementBrowserArgs) => (
  <MatomoProvider value={matomoInstance}>
    <HelmetProvider>
      <RootElementWrapper {...props} />
    </HelmetProvider>
  </MatomoProvider>
);

export const wrapPageElement = (
  props: WrapPageElementBrowserArgs<TPageData, TPageContext, TPageState>,
) => <PageElementWrapper {...props} />;

export const replaceHydrateFunction = () => {
  return (element: ReactElement, container: Element | DocumentFragment) => {
    const root = createRoot(container);
    root.render(element);
  };
};

type TLocation = { pathname: string };
type TWindow = Window & typeof globalThis & { locations: TLocation[]; previousLocation: TLocation };

export function onRouteUpdate({ location }: RouteUpdateArgs) {
  const locations = (window as TWindow).locations || [];
  if (locations[locations.length - 1]?.pathname !== location.pathname) {
    locations.push(location);
  }

  (window as TWindow).locations = locations;
  (window as TWindow).previousLocation = locations[locations.length - 2];
}
