import { Box, Toolbar } from '@mui/material';
import { graphql, useStaticQuery } from 'gatsby';
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews';
import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { HeaderQuery } from '../../../graphql-types';
import AuthPage from '../../components/auth-page';
import usePrismicNodes from '../../hooks/prismic-nodes';
import { languagesMap } from '../../languages';
import { TPageProps } from '../../page-props';
import AppContentLayout from '../app-content';

import Drawer from './drawer';
import Footer from './footer';
import Header from './header';

function Layout({ element, ...props }: TPageProps & { element: ReactElement }) {
  const [drawerOpen, toggleDrawer] = useState(false);
  const {
    t,
    i18n: { language: currentLanguage },
  } = useTranslation();
  const staticQuery = useStaticQuery<HeaderQuery>(graphql`
    fragment PrismicHeaderLinks on PrismicHeaderDataBodyLinks {
      id
      slice_type
      items {
        item_key {
          text
        }
        icon {
          gatsbyImageData(placeholder: NONE)
        }
        active_icon {
          gatsbyImageData(placeholder: NONE)
        }
        item_link {
          link_type
          slug
          url
        }
        item_title {
          text
        }
        item_parent_key {
          text
        }
      }
    }
    fragment HeaderData on PrismicHeaderData {
      body {
        ... on PrismicHeaderDataBodyLinks {
          ...PrismicHeaderLinks
        }
      }
      profile_button_label {
        text
      }
      signin_button_label {
        text
      }
    }
    query Header {
      allPrismicHeader {
        nodes {
          lang
          data {
            ...HeaderData
          }
        }
      }
      allPrismicHome {
        nodes {
          lang
          data {
            title {
              text
            }
          }
        }
      }
    }
  `);
  const query = useMergePrismicPreviewData(staticQuery);
  const { data: headerData } = usePrismicNodes(query?.allPrismicHeader.nodes, {
    currentLanguage,
  });
  const title = query?.allPrismicHome.nodes.find(
    ({ lang }) => languagesMap[lang] === currentLanguage,
  )?.data.title?.text;

  const {
    location: { pathname, search },
    pageContext: { pageLayout, disableFooter },
  } = props;
  const showcase = !pageLayout || pageLayout === 'showcase';

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        sx={{
          ...(showcase ? { minHeight: '100vh' } : { height: '100vh' }),
          '@supports (-webkit-touch-callout: none)': showcase
            ? { minHeight: '-webkit-fill-available' }
            : { height: '-webkit-fill-available' },
        }}
      >
        <Header
          {...props}
          data={headerData}
          drawerOpen={drawerOpen}
          showcase={showcase}
          title={title || t('geovelo.pages_titles.home') || 'Geovelo'}
          toggleDrawer={toggleDrawer}
        />
        <Toolbar sx={{ flexShrink: 0, height: 72 }} />
        <Box
          display="flex"
          flexDirection="column"
          flexGrow={1}
          sx={{ backgroundColor: '#fff', overflowY: showcase ? undefined : 'hidden' }}
        >
          {pageLayout === 'leftPanel' || pageLayout === 'details' ? (
            <AppContentLayout {...props}>{element}</AppContentLayout>
          ) : pageLayout === 'private' ? (
            <AuthPage path={pathname} search={search}>
              {element}
            </AuthPage>
          ) : (
            <>{element}</>
          )}
        </Box>
        {showcase && !disableFooter && <Footer />}
      </Box>
      <Drawer {...props} headerData={headerData} open={drawerOpen} toggle={toggleDrawer} />
    </>
  );
}

export default Layout;
