import { navigate } from 'gatsby';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../context';
import LoadingPage from '../templates/loading';

function AuthPage({
  path,
  search,
  children,
}: {
  children: JSX.Element;
  path: string;
  search: string;
}) {
  const {
    user: { current: currentUser },
  } = useContext(AppContext);
  const {
    i18n: { language: currentLanguage },
  } = useTranslation();

  useEffect(() => {
    if (currentUser === null)
      navigate(`/${currentLanguage}/sign-in`, { state: { prevPath: path, search } });
  }, [currentUser]);

  if (currentUser === null) return <></>;

  if (currentUser === undefined && typeof window !== 'undefined')
    return <LoadingPage loadingTextKey="commons.user.loading" />;

  return <>{children}</>;
}

export default AuthPage;
