import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

export function WorkIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon viewBox="0 0 6.35 6.35" {...props}>
      <path
        d="M1.228 292.201a.433.433 0 0 0-.432.432v2.84c0 .23.188.431.432.431h3.894a.434.434 0 0 0 .432-.432v-2.84a.433.433 0 0 0-.432-.431z"
        fill="#cfa15a"
        transform="translate(0 -290.65)"
      />
      <path
        d="M2.76 291.746c-.108 0-.208.03-.288.088a.327.327 0 0 0-.143.26c0 .11.06.208.14.27.082.06.183.089.292.089h.828c.11 0 .21-.03.291-.09.081-.06.14-.16.14-.27a.327.327 0 0 0-.142-.26.488.488 0 0 0-.289-.087zm0 .264h.83c.058 0 .106.019.134.039.027.02.031.033.031.045 0 .021-.006.036-.033.056a.227.227 0 0 1-.133.04h-.828a.226.226 0 0 1-.133-.04c-.027-.02-.033-.035-.033-.056 0-.012.004-.025.031-.045a.237.237 0 0 1 .135-.04z"
        fill="#615535"
        transform="translate(0 -290.65)"
      />
      <path
        d="M1.228 292.2a.434.434 0 0 0-.431.433v.439c0 .023.006.045.017.065l.11.193.018.03a.132.132 0 0 0 .116.068H1.1l2.026.772c.03.012.063.012.093.001l2.118-.781a.76.76 0 0 0 .013-.006l.13-.065a.132.132 0 0 0 .074-.119v-.598a.434.434 0 0 0-.432-.432zm1.946 1.736c-2.117 2.042-1.058 1.021 0 0z"
        fill="#c18538"
        transform="translate(0 -290.65)"
      />
      <path
        d="M3.073 293.52a.433.433 0 0 0-.431.431v.203c0 .237.194.432.431.432h.192a.433.433 0 0 0 .431-.432v-.203a.433.433 0 0 0-.431-.432z"
        fill="#9a6a44"
        transform="translate(0 -290.65)"
      />
    </SvgIcon>
  );
}
